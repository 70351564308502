'use strict';

document.addEventListener('DOMContentLoaded', function() {

  /**
   * パラメータ取得関数
   * @argument name 取得したいパラメータ名
   * @argument url 表示ページ以外のURLから取得したい場合
   */
  function getParam(name, url) {
    if (!url) url = window.location.search;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    let result = decodeURIComponent(results[2].replace(/\+/g, " "));
    return htmlspecialchars(result);
  }

  
  /**
   * エスケープ関数
   * @argument unsafeText エスケープしたい文字列
   */
  function htmlspecialchars(unsafeText){
    if(typeof unsafeText !== 'string'){
      return unsafeText;
    }
    return unsafeText.replace(/[&'`"<>]/g, function(match) {
        return {
          '&': '&amp;',
          "'": '&#x27;',
          '`': '&#x60;',
          '"': '&quot;',
          '<': '&lt;',
          '>': '&gt;',
        }[match]
      }
    );
  }

  /**
   * ページトップスクロールの表示処理
   * @variable element ページトップエレメント
   */
  window.addEventListener('scroll', function() {
    var element = document.querySelector('.m-pagetop');
    if (window.pageYOffset > 200) {
      // If we've scrolled 200px, add the "is-visible" class
      element.classList.add('is-visible');
    } else {
      // Otherwise, remove it
      element.classList.remove('is-visible');
    }
  });


  /**
   * アンカースクロール
   * @variable headerElement 固定ヘッダーセレクタ
   * @variable headerElement スクロール速度
   */
  const headerClass = '.l-header_logo';

  let scroll = new SmoothScroll('a[href*="#"]', {
    speedAsDuration: true,
    speed: 500,
    header: headerClass,
    easing: 'easeInOutQuint',
    offset: 30
  });


  // ページ表示時のアンカー位置にヘッダーの高さを考慮する
  const headerElement = document.querySelector(headerClass);
  let headerElementHeight = 0;

  // ロード時にヘッダーが存在していれば高さを取得
  if ( headerElement !== null ) {
    headerElementHeight = headerElement.offsetHeight - 100;
  }
  // ハッシュ値があればロード時に高さを考慮した位置へスクロール位置を差し替え
  if ( window.location.hash !== '' ) {
    window.addEventListener('load', function() {
      const target = document.querySelector(window.location.hash);
      if ( target !== null ) {
        const position = target.offsetTop - headerElementHeight - 100;
        document.documentElement.scrollTop = position;
      }
    });
  }

  /**
   * フッター：コピーライト年
   */
  const copyright = document.querySelector('.js-copyright');
  const currentYear = new Date().getFullYear();
  copyright.innerHTML = currentYear;

  /**
   * ハンバーガーナビゲーション
   */
  const lNav = document.querySelector('.l-nav');
  const lNavAnchor = document.querySelectorAll('.l-nav a');
  const lNavTrigger = document.querySelector('.l-navTrigger');
  const lNavCurtain = document.querySelector('.l-navCurtain');
  lNavTrigger.addEventListener('click', function() {

    lNavTrigger.classList.toggle('is-open');
    
    // メニューを開いている場合は閉じる
    if ( lNavCurtain.classList.contains('is-in') ) {
      lNavCurtain.classList.remove('is-in');
      lNavCurtain.classList.add('is-out');
      lNav.classList.remove('is-show');
      document.documentElement.classList.remove('is-fixed');
    } else {
      // メニューを開いていない場合は開ける
      lNavCurtain.classList.remove('is-out');
      lNavCurtain.classList.add('is-in');
      lNav.classList.add('is-show');
      document.documentElement.classList.add('is-fixed');
      // document.documentElement.style.setProperty('top', '-' + scrollPosition.toString() + 'px');
    }

  });

  lNavAnchor.forEach(function (lNavAnchorItem) { // > Uncaught TypeError
    lNavAnchorItem.addEventListener('click', function() {
      lNavCurtain.classList.remove('is-in');
      lNav.classList.remove('is-show');
      lNavTrigger.classList.remove('is-open');
    });
  });

  /**
   * Javascriptによるメディアクエリ
   * @variable mediaQueryList PC以上の場合
   */
  const mediaQueryList = window.matchMedia('(min-width: 751px)');
  handleMediaQuery(mediaQueryList);

  function handleMediaQuery(e) {
    if (e.matches) {
      // min-width: 751px (PC)
      if ( document.querySelector('.service-list_process_phase') !== null ) {
        $('.service-list_process_phase.slick-initialized').slick('unslick');
      }

    } else {
      // max-width: 750px (SP)
      if ( document.querySelector('.service-list_process_phase') !== null ) {
        $('.service-list_process_phase').not('.slick-initialized').slick({
          centerMode: true,
          centerPadding: '5%',
          slidesToShow: 1,
          variableWidth: true,
          prevArrow: '<div class="service-list_process_phase_arrow service-list_process_phase_arrow-prev"><img src="/_/media/page/service/phase_arrow_prev.svg" alt=""></div>',
          nextArrow: '<div class="service-list_process_phase_arrow service-list_process_phase_arrow-next"><img src="/_/media/page/service/phase_arrow_next.svg" alt=""></div>',
          infinite: false,
            dots: true,
            // autoplay: true,
        });
      }
    }
  }
  mediaQueryList.addEventListener('change', handleMediaQuery);


  /**
   * スマートフォン表示でブラウザUIを考慮する処理
   * @memo 下記CSSを全画面表示したい要素に設定する
   * @memo min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100);
   */
  // const setFillHeight = function() {
  //   const vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty('--vh', vh + 'px');
  // }

  // // 画面のサイズ変動があった時に高さを再計算する
  // window.addEventListener('resize', setFillHeight);

  // // 初期化
  // setFillHeight();


  /**
   * アクセシビリティを考慮したスライダー
   * https://ja.splidejs.com/
   */
  /*
  <div class="splide js-slider" aria-label="screen-read-name">
    <div class="splide__track">
      <ul class="splide__list">
        <li class="splide__slide"></li>
      </ul>
    </div>
    <div class="splide__arrows">
      <button class="splide__arrow splide__arrow--prev">Prev</button>
      <ul class="splide__pagination"></ul>
      <button class="splide__arrow splide__arrow--next">Next</button>
    </div>
  </div>
  */
  if ( document.querySelector('.recruit-slide') !== null ) {
    let jsSlider = new Splide('.recruit-slide', {
      
      perPage       : 4,      // 1ページの表示数
      perMove       : 1,      // 1度にスライドを何枚移動するか
      speed         : 800,    // 移動時間（ミリ秒単位）
      type          : 'loop', // ループする場合
      interval      : 4000,   // 自動再生の間隔（ミリ秒単位）
      pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
      resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
      updateOnMove  : true,   // is-activeクラスを移動前に更新する
      gap           : '30px', // スライド間の余白（cssと同様）
      padding       : '50px', // 左右の見切れる幅（cssと同様）
      easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
      focus         : 'center',

      // インビュー時から自動再生をスタート（拡張機能：splide-extension-intersection.js）
      autoplay: 'pause',
      intersection: {
        inView: {
          autoplay: true,
        },
        outView: {
          autoplay: false,
        },
        once: true, // 一度画面に表示されたら監視を停止する
      },

      // 動き続けるスライダーを実装（拡張機能：splide-extension-auto-scroll.js）
      autoScroll: {
        speed: 1, // 負の値で流れる向きが右へ変更
        pauseOnHover: false,
        pauseOnFocus: false,
        
      },

      // メディアクエリ設定（初期：751px以上の場合、スライダーを動作させない）
      mediaQuery: 'max',
      breakpoints: {
        750: {
          perPage       : 1,      // 1ページの表示数
        },
      },
    });

    // スライダーに過不足がある場合にスライダーUIを停止・排除する
    jsSlider.on('overflow', function ( isOverflow ) {
      jsSlider.go(0);
      jsSlider.options = {
        arrows    : isOverflow,
        pagination: isOverflow,
        drag      : isOverflow,
        clones    : isOverflow ? undefined : 0,
      };
    } );

    // スライダーを実行
    // jsSlider.mount();
    jsSlider.mount(window.splide.Extensions);

  }


  if ( document.querySelector('.service-works_slide') !== null ) {
    let jsSlider = new Splide('.service-works_slide', {

      perPage       : 4,      // 1ページの表示数
      perMove       : 1,      // 1度にスライドを何枚移動するか
      speed         : 800,    // 移動時間（ミリ秒単位）
      type          : 'loop', // ループする場合
      interval      : 4000,   // 自動再生の間隔（ミリ秒単位）
      pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
      resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
      updateOnMove  : true,   // is-activeクラスを移動前に更新する
      gap           : '30px', // スライド間の余白（cssと同様）
      padding       : '50px', // 左右の見切れる幅（cssと同様）
      easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
      focus         : 'center',

      // インビュー時から自動再生をスタート（拡張機能：splide-extension-intersection.js）
      autoplay: 'pause',
      intersection: {
        inView: {
          autoplay: true,
        },
        outView: {
          autoplay: false,
        },
        once: true, // 一度画面に表示されたら監視を停止する
      },

      // 動き続けるスライダーを実装（拡張機能：splide-extension-auto-scroll.js）
      autoScroll: {
        speed: 1, // 負の値で流れる向きが右へ変更
        pauseOnHover: false,
        pauseOnFocus: false,

      },

      // メディアクエリ設定（初期：751px以上の場合、スライダーを動作させない）
      mediaQuery: 'max',
      breakpoints: {
        750: {
          perPage       : 1,      // 1ページの表示数
        },
      },
    });

    // スライダーに過不足がある場合にスライダーUIを停止・排除する
    jsSlider.on('overflow', function ( isOverflow ) {
      jsSlider.go(0);
      jsSlider.options = {
        arrows    : isOverflow,
        pagination: isOverflow,
        drag      : isOverflow,
        clones    : isOverflow ? undefined : 0,
      };
    } );

    // スライダーを実行
    // jsSlider.mount();
    jsSlider.mount(window.splide.Extensions);

  }



  /**
   * AOSアニメーション
   * @variable element ページトップエレメント
   * https://michalsnik.github.io/aos/
   * https://github.com/michalsnik/aos
   */
  AOS.init({
    offset: 300,
    duration: 800,
    once: true
  });



  /**
   * マウスストーカー
   * @variable stalker ストーカーエレメント
   * https://michalsnik.github.io/aos/
   * https://github.com/michalsnik/aos
   */
  // let scrollPosition = window.pageYOffset;
  // window.addEventListener('scroll', function() {
  //   scrollPosition = window.pageYOffset;
  // });
  // if (document.querySelector('.js-stalker') !== null ) {
  //     const stalker = document.querySelector('.js-stalker');
  //     const stalkerSp = document.querySelector('.js-stalkerSp');
  //       // 目標の位置
  //     let targetX = 0, targetY = 0;
  //     // 現在の位置
  //     let currentX = 0, currentY = 0;
  //     // マウス追従の遅れ度を決定する（値が小さいほど追従が早い）
  //     const delay = 0.1;

  //     document.addEventListener('mousemove', (e) => {
  //       targetX = e.pageX;
  //       targetY = e.pageY;
  //     });



  //     function animate() {

  //       // 現在の位置を目標の位置に近づける
  //       currentX += (targetX - currentX) * delay;
  //       currentY += (targetY - currentY) * delay;

  //       // ストーカーの位置を更新（PC）
  //       stalker.style.left = currentX + 'px';
  //       stalker.style.top =  currentY - scrollPosition + 'px';

  //       // 次のフレームのアニメーションを要求
  //       requestAnimationFrame(animate);
  //     }

  //     // アニメーションを開始
  //     animate();


  //     document.querySelectorAll('[data-stalker-area]').forEach(function(element) {
  //       //マウスが要素上に入った時
  //       element.addEventListener('mouseover', () => {
  //         stalker.classList.add('is-stalking');
  //       }, false);

  //       //マウスが要素上から離れた時
  //       element.addEventListener('mouseleave', () => {
  //         stalker.classList.remove('is-stalking');
  //       }, false);
  //     })

  //     document.querySelectorAll('[data-stalker-anchor]').forEach(function(element) {
  //       //マウスが要素上に入った時
  //       element.addEventListener('mouseover', () => {
  //         stalker.classList.add('is-stalking-hover');
  //         element.closest('.works-main_list_item').classList.add('is-hover');
  //       }, false);

  //       //マウスが要素上から離れた時
  //       element.addEventListener('mouseleave', () => {
  //         stalker.classList.remove('is-stalking-hover');
  //         element.closest('.works-main_list_item').classList.remove('is-hover');
  //       }, false);
  //     })

  //     const worksModal = document.querySelector('.js-modal');
  //     const targetElement = document.querySelector('.works-main_modal');
  //     document.querySelectorAll('.works-main_list_item_anchor').forEach(function(element) {
  //       element.addEventListener('click', function(event) {
  //         event.preventDefault();
  //         document.documentElement.classList.add('is-fixed');
  //         // document.documentElement.style.setProperty('top', '-' + scrollPosition.toString() + 'px');

  //         // ブラウザのビューポートの幅と高さを取得
  //         let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  //         let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  //         // それぞれを2で割ることで中心の座標を計算
  //         let centerX = width / 2;
  //         let centerY = height / 2;

  //         // ストーカーの位置を更新（SP）
  //         stalkerSp.style.left = event.pageX + 'px';
  //         stalkerSp.style.top =  event.pageY - scrollPosition + 'px';

  //         stalker.classList.add('is-open');
  //         stalkerSp.classList.add('is-open');
  //         targetElement.classList.add('is-open');
  //       })

  //     })

  //     targetElement.addEventListener('click', function(event) {
  //       stalker.classList.remove('is-open');
  //       stalkerSp.classList.remove('is-open');
  //       targetElement.classList.remove('is-open');
  //       document.documentElement.classList.remove('is-fixed');
  //     })
  //   }




});

